import React from 'react';
import {Layout} from "../components/Layout";
import {BackgroundLine} from "../components/BackgroundLine";
import {graphql} from "gatsby";
import {Sections} from "../components/Sections";
import {HeroInternship} from "../components/HeroInternship";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {useIntl} from "gatsby-plugin-react-intl";

export const query = graphql`
    query Privacy($locale: String!) {
        page: strapiSpecialPage(locale: {eq: $locale}, slug: {eq: "internship"}) {
            title
            hero {
                ...SectionHero
            }
            sections {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
    }
`
export default function InternshipPage({data, pageContext}) {
    const intl = useIntl();
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.page.title}
                   seo={data.page.seo}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   lang={pageContext.lang}>
        <HeroInternship title={data.page.hero.title} content={data.page.hero.content.data}
                        ctaButton={data.page.hero.ctaButtonRef}
                        breadcrumbs={
                            <Breadcrumbs>
                                <Breadcrumbs.Item
                                    to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                                <Breadcrumbs.Item
                                    isLast={true}>{intl.formatMessage({id: 'menu.internship'})}</Breadcrumbs.Item>
                            </Breadcrumbs>
                        }/>

        <Sections
            sections={data.page.sections}
        />
    </Layout>;
}

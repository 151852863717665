import React from 'react';
import classNames from "classnames";

import * as styles from './HeroInternship.module.scss';
import {BlockTitle} from "../BlockTitle";
import {hasMarkdownContent} from "../../functions/hasMarkdownContent";
import {MarkdownRenderer} from "../MarkdownRenderer";
import {StaticImage} from "gatsby-plugin-image";
import {CTAButtonNew} from "../CTAButtonNew";

export function HeroInternship({className, title, breadcrumbs, ctaButton, content, ...props}) {
    return <section className={classNames(className, styles.root)} {...props}>
        {breadcrumbs && <div className={styles.breadcrumbs}>{breadcrumbs}</div>}
        <div className={styles.contentWrapper}>
            <BlockTitle className={styles.title}>{title}</BlockTitle>
            {
                hasMarkdownContent(content) &&
                <MarkdownRenderer
                    className={styles.content}
                    content={content}/>
            }
            {ctaButton && <CTAButtonNew {...ctaButton} className={styles.cta}/>}
        </div>
        <div className={styles.pictureBlue}>
            <StaticImage src={'../../assets/internship_hero_blue.png'} placeholder="none"
                         className={styles.image}
                         alt={'Internship'}/>
        </div>
        <div className={styles.picturePink}>
            <StaticImage src={'../../assets/internship_hero_pink.png'} placeholder="none"
                         className={styles.image}
                         alt={'Internship'}/>
        </div>
    </section>;
}
